import WalletConnectImg from "../assets/images/wallet-connect.svg"
import MetamaskImg from "../assets/images/metamask.png"
import { createContext } from "react"
import { useState } from "react"
import { useEffect } from "react"
import { getBrowserProvider } from "./util"
import { BrowserCrossProvider, WalletConnectCrossProvider } from "./CrossProvider"
import { limitDecimals } from "./util"
import ConnectModal from "./ConnectModal"
import { chains } from "./chains"
import { toast } from "react-hot-toast"

export const supportedConnections = [
	{
		label: "Wallet Connect",
		key: "walletconnect",
		img: WalletConnectImg
	},
	{
		label: "Metamask",
		key: "metamask",
		img: MetamaskImg,
		hide: () => !window.ethereum
	},
]

export const Web3Context = createContext({
  connected: false,
  account: null,
  connectedConnection: null,
  modalOpen: false,
  connect: () => Promise.reject("No context provided"),
  disconnect: () => Promise.reject("No context provided"),
  sendTransaction: () => Promise.reject("No context provided"),
  signMessage: () => Promise.reject("No context provided"),
  showConnectionModal: () => {}
})

export const Web3ContextWrapper = (props) => {
  const [ connected, setConnected ] = useState(false)
  const [ account, setAccount ] = useState(null)
  const [ connectedConnection, setConnectedConnection ] = useState(null)
  const [ modalOpen, setModalOpen ] = useState(false)
	const localConnected = localStorage.getItem("connectedWalletType")
  const [ web3Modal, setWeb3Modal ] = useState(null)
  const [ crossProvider, setCrossProvider ] = useState(null)

  useEffect(() => {
    if (connectedConnection) localStorage.setItem("connectedWalletType", connectedConnection)
      else localStorage.removeItem("connectedWalletType")
  }, [connectedConnection])

  const getModal = async () => {
    const { createWeb3Modal, defaultConfig } = await import("@web3modal/ethers")

    const metadata = {
      name: "BlueNode",
      description: "BlueNode",
      url: "https://bluenode.ai",
      icons: ["https://bluenode.ai/logo192.png"]
    }

    const config = defaultConfig({
      metadata
    })

    const modal = createWeb3Modal({
      projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
      chains: Object.values(chains),
      ethersConfig: config,
      allowUnsupportedChain: false,
      themeMode: "dark",
      metadata
    })

    setWeb3Modal(modal)
    return modal
  }

  const detectMetamaskConnection = async () => {
    const eth = getBrowserProvider()
    if (!eth) return;
    const accounts = await eth.request({method: "eth_accounts"})
    if (accounts.length > 0) {
      await disconnect()
      await connectMetamask()
    }
  }

  const detectWalletConnectConnection = async () => {
    const modal = await getModal()
    await new Promise((resolve) => modal.subscribeProvider((e) => {
      if (e.provider) resolve()
    }))
    const provider = modal.getWalletProvider()
    if (!provider) return;
    const crossProvider = await WalletConnectCrossProvider.from({
      modal,
      provider
    })
    const account = await crossProvider.getAccount()
    setCrossProvider(crossProvider)
    setConnected(true)
    setAccount(account)
    setConnectedConnection("walletconnect")
  }

  useEffect(() => {
    if (localConnected === "metamask") detectMetamaskConnection()
    else if (localConnected === "walletconnect") detectWalletConnectConnection()
  }, [])

  const connectMetamask = async () => {
    const eth = getBrowserProvider()
    if (!eth) return
    const crossProvider = await BrowserCrossProvider.from(eth)
    const account = await crossProvider.getAccount()
    setAccount(account)
    setCrossProvider(crossProvider)
    setConnectedConnection("metamask")
    setConnected(true)
    eth.on("accountsChanged", (accounts) => {
      if (accounts.length === 0) return disconnect()
        setAccount(accounts[0])
    })
  }

  const connectWalletConnect = async () => {
    let modal = web3Modal
    if (!modal) modal = await getModal()
    setConnected("walletconnect")
    await modal.open()
    await new Promise((resolve, reject) => {
      if (!modal) return reject("No modal")
      modal.subscribeProvider(async (e) => {
        if (!modal || !e.provider) return reject("No modal")
        const crossProvider = await WalletConnectCrossProvider.from({
          modal,
          provider: e.provider
        })
        const account = await crossProvider.getAccount()
        setCrossProvider(crossProvider)
        setAccount(account)
        setConnected(true)
        resolve()
      })
    })
  }

  const connectFunctions = {
    metamask: connectMetamask,
    walletconnect: connectWalletConnect
  }

  const connect = async (connection) => {
    try {
      const func = connectFunctions[connection]
      if (!func) return
      await func()
      if (!crossProvider) return
      crossProvider.onDisconnect(disconnect)
      toast.success("Successfully connected wallet")
    } catch(err) {
      if ("message" in err) toast.error(err.message)
      else toast.error("Error sending transaction")
      throw err
    }
  }

  const disconnect = async () => {
    if (crossProvider) await crossProvider.disconnect()
    setCrossProvider(null)
    setAccount(null)
    setConnected(false)
    setConnectedConnection(null)
  }

  const handleErrors = (e) => {
		if (typeof e === "object" && e !== null && "code" in e) {
			if (e.code === "INSUFFICIENT_FUNDS") {
        toast.error("Insufficient funds")
        throw new Error("Insufficient funds")
			}
		}
	}

  const sendTransaction = async (args) => {
    args.value = limitDecimals(Number.parseFloat(args.value), (args.decimals ?? 18) - 1);
    if (args.value.includes("e-")) throw new Error("Value too small")
    else if (args.value.includes("e")) throw new Error("Value too large")
    try {
      if (!crossProvider) throw new Error("No provider")
      const chainId = await crossProvider.getChainId()
      const isMultiChain = chainId === null;
      if (!isMultiChain && args.chainId !== chainId) {
        await crossProvider.switchChain(args.chainId)
      }

      if (args.native) {
        await crossProvider.sendNativeTransaction({
          chainId: args.chainId,
          to: args.to,
          value: args.value
        })
      } else {
        if (!args.abi) throw new Error("No abi provided")
        if (!args.contractAddress) throw new Error("No contract address provided")
        await crossProvider.transferToContract({
          abi: args.abi,
          contractAddress: args.contractAddress,
          data: {
            to: args.to,
            value: args.value,
          },
          decimals: args.decimals ?? 18,
          chainId: args.chainId
        })
      }
    } catch (err) {
      handleErrors(err)
      if ("message" in err) toast.error(err.message)
      else toast.error("Error sending transaction")
      throw err
    }
  }

  const signMessage = (data) => {
    if (!connected) throw new Error("Not connected")
    if (!crossProvider) throw new Error("No provider")
    return crossProvider.signMessage(data)
  }

  useEffect(() => {
    if (account) setModalOpen(false)
  }, [account])

  const value = {
    connected,
    account,
    connectedConnection,
    modalOpen,
    connect,
    disconnect,
    sendTransaction,
    signMessage,
    showConnectionModal: () => setModalOpen(true)
  }

  return (
    <Web3Context.Provider value={value}>
      <ConnectModal open={modalOpen} onClose={() => setModalOpen(false)} />
      {props.children}
    </Web3Context.Provider>
  )
}
