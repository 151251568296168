import { chains } from "./chains";

export const getSignerFromOptions = async (provider, options, library) => {
  const { ethers } = await import("ethers");
  const privateKey = options && 'privateKey' in options && options.privateKey;
  const json = options && 'json' in options && options.json;
  const password = options && 'password' in options && options.password;
  const privateKeySigner = privateKey && provider && new ethers.Wallet(privateKey, provider);
  const encryptedJsonSigner = json && password && provider && ethers.Wallet.fromEncryptedJsonSync(json, password).connect(provider);
  const optionsSigner = options && 'signer' in options && options.signer;
  return privateKeySigner || encryptedJsonSigner || optionsSigner || library?.getSigner();
};

const rpcMap = {
  "1": "https://rpc.ankr.com/eth",
  "56": "https://bsc-dataseed1.binance.org/",
  "900": "https://api.mainnet-beta.solana.com"
};

export const getBalanceOfWalletAddress = async (walletAddress, chainId, tokenContractAddress, decimals) => {
  const { ethers } = await import("ethers");
  const provider = ethers.getDefaultProvider(rpcMap[chainId.toString()]);
  const abi = getAbi(chainId)
  if (tokenContractAddress) {
    const contract = new ethers.Contract(tokenContractAddress, abi, provider);
    const balance = await contract.balanceOf(walletAddress);
    return ethers.formatUnits(balance, decimals ?? 18);
  }
  const balance = await provider.getBalance(walletAddress);
  return ethers.formatUnits(balance, decimals ?? 18);
};

export const getDefaultProvider = (ethers, chainId) => {
  return ethers.getDefaultProvider(rpcMap[chainId.toString()]);
};

export const getChainIdFromLabel = (label) => {
  return Object.values(chains)
    .find((chain) => chain.label === label)?.chainId ?? null
};

export const getChainIdFromSymbol = (symbol) => {
  return {
    "USDT": chains.eth.chainId,
    "ETH": chains.eth.chainId,
    "BNB": chains.bsc.chainId
  }[symbol.toUpperCase()] ?? null
};


export const isCurrencyNative = (symbol, chainId) => {
  return Object.values(chains)
    .find((chain) => chain.chainId === chainId && chain.currency === symbol.toUpperCase()) !== undefined
};

export const getAbi = (chainId) => {
  return Object.values(chains)
    .find((chain) => chain.chainId === chainId)
    .abi ?? null
};

export const getContractAddress = (symbol) => {
  return {
    "USDT": "0xdAC17F958D2ee523a2206206994597C13D831ec7"
  }[symbol.toUpperCase()] ?? null;
};

export const getDecimals = (symbol) => {
  return {
    "USDT": 6
  }[symbol.toUpperCase()] ?? null;
};

export const getBrowserProvider = () => {
  return window.ethereum;
};

export const cutoffDecimals = (num, decimals) => {
  const index = num.indexOf(".");
  if (index === -1)
    return num;
  const numDecimals = num.length - 1 - index;
  if (numDecimals <= decimals)
    return num;
  return num.substring(0, index + decimals);
};

export const limitDecimals = (num, decimals) => {
	let str = num.toPrecision(decimals)
	const [ intStr, decimalStr ] = str.split(".")
	if (!decimalStr) return str;
	if (decimalStr.length + intStr.length <= decimals) return str
	str = str.substring(0, decimals)
	if (str.endsWith(".")) str = str.slice(0, -1)
	return str
}

export const toHex = (num, pad) => {
	let hex = num.toString(16)
	if (pad && hex.length % 2 === 1) hex = `0${hex}`
	return `0x${hex}`
}

const minDecimalPlaces = 0
const maxDecimalPrecision = 8

export const removeTrailingZeros = (numStr, minDecimals = minDecimalPlaces) => {
	numStr = numStr.toString()
	const numSplit = numStr.split(".")
	if (numSplit.length < 2) return numStr
	const integerStr = numSplit[0]
	const decimalStr = numSplit[1]
	if (decimalStr.length <= minDecimals) return ""
	const trailingZerosMatch = decimalStr.match(/0*$/)
	if (!trailingZerosMatch) return "";
	let trailingZerosStr = trailingZerosMatch[0]
	const precisionDecimalStr = decimalStr.substring(0, trailingZerosMatch.index)
	trailingZerosStr = trailingZerosStr.substring(0, minDecimals - (decimalStr.length - trailingZerosStr.length))
	return `${integerStr}.${precisionDecimalStr}${trailingZerosStr}`
}

export const formatPrecision = (num, minDecimals = minDecimalPlaces, maxPrecision = maxDecimalPrecision) => {
	num = Math.floor(num * Math.pow(10, maxDecimalPrecision) + 0.5) / Math.pow(10, maxDecimalPrecision)
	let numStr = removeTrailingZeros(num.toLocaleString("fullwide", {useGrouping: false, minimumSignificantDigits: 3, minimumFractionDigits: 3}))
	let decimals = 0
	if (numStr.includes(".")) {
		decimals = numStr.split(".")[1].length
	}
	if (decimals < minDecimals) {
		if (decimals === 0) numStr = numStr + "."
		for (var i = decimals; i < minDecimals; i++) {
			numStr = numStr + "0"
		}
	}
	if (decimals > 0) {
		const numSplit = numStr.split(".")
		const integerStr = numSplit[0]
		const decimalStr = numSplit[1]
		let nonZeroDecimals = (decimalStr.match(/[1-9][0-9]*/) || [""])[0]
		const nonZeroDecimalCount = nonZeroDecimals.length
		let zeroDecimals = (decimalStr.match(/0*/) || [""])[0]
		let decimals = zeroDecimals + nonZeroDecimals
		if (integerStr !== "0" && decimals.length > maxPrecision) {
			decimals = decimals.substring(0, maxPrecision)
			numStr = `${integerStr}.${removeTrailingZeros(decimals, minDecimals)}`
		} else if (nonZeroDecimalCount > maxPrecision) {
			nonZeroDecimals = nonZeroDecimals.substring(0, maxPrecision)
			numStr = `${integerStr}.${zeroDecimals}${removeTrailingZeros(nonZeroDecimals, minDecimals)}`
		}
	}

	return numStr
}

export const addCommas = (num) => {
	const str = num.toString()
	const split = str.split(".")
	const decimals = split[1]
	const int = split[0]
	return int
		.split('')
		.reverse()
		.map((char, i) => i % 3 === 2 ? `,${char}` : char)
		.reverse()
		.join('')
		.replace(/(,$)|(^,)/, '') + (decimals ? "." + decimals : "")
}

export const formatNumber = (num, minDP, maxDP) => {
	return addCommas(formatPrecision(num, minDP, maxDP))
}

const letterMap = {
	"K": 1_000,
	"M": 1_000_000,
	"B": 1_000_000_000,
	"T": 1_000_000_000_000,
	"Q": 1_000_000_000_000_000,
}

export const formatLargeNumber = (num, precisionCutoff = 1000, minDP = 2, maxDP = 2) => {
	if (num < precisionCutoff) return formatNumber(num, minDP, maxDP)
	num = Math.floor(num);
	let newNum = num;
	let suffix = ""
	Object.entries(letterMap).forEach(([letter, divisor]) => {
		if (num / divisor < 1000 && num / divisor >= 1) {
			suffix = letter
			newNum = num / divisor;
		}
	})
	return `${roundToDP(newNum, 2)}${suffix}`
}

export const roundToDP = (num, decimalPlaces) => {
	return (Math.floor(num * 10**decimalPlaces) / 10**decimalPlaces).toString()
}

export const parseNum = (num) =>  {
	if (!num) return 0
	if (typeof num === "string") return Number.parseFloat(num)
	return num
}