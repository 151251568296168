import clsx from "clsx"
import { Web3Context, supportedConnections } from "./Web3Context"
import { useContext } from "react"
import "./ConnectModal.scss"

const ConnectModal = (props) => {
  const web3 = useContext(Web3Context)

  return (
    <div className={clsx("connect_modal-container", {open: props.open})}>
      <div className="connect_modal-backdrop" onClick={props.onClose} />
      <div className="connect_modal-stroke">
        <div className="connect_modal-card">
          <button aria-label="Close" className="connect_modal-close" onClick={props.onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
          <p className="connect_modal-title">Connect your Wallet</p>
          {supportedConnections.filter((connection) => connection.hide === undefined || !connection?.hide()).map((connection) => (
            <button key={connection.key} className="connect_modal-option" onClick={async () => await web3.connect(connection.key).catch((err) => console.log(err))}>
              <img src={connection.img} className="connect_option-img" alt={`${connection.label}'s icon`} />
              {connection.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ConnectModal