import { cutoffDecimals, getDefaultProvider, toHex } from "./util";
export class CrossProvider {
}
export class BrowserCrossProvider extends CrossProvider {
  provider;
  constructor(provider, manualCall = true) {
    super();
    if (manualCall)
        throw new Error("Can't call the constructor directly, use CrossProvider.from() instead");
    this.provider = provider;
  }
  static async from(provider) {
    return new BrowserCrossProvider(provider, false);
  }
  async disconnect() {
    await this.provider.removeAllListeners();
  }
  async sendNativeTransaction(tx) {
    const { ethers } = await import("ethers");
    const account = await this.getAccount();
    if (!account)
        throw new Error("No account selected");
      
    await this.provider.request({
      method: "eth_sendTransaction",
      params: [{
        to: tx.to,
        from: account,
        value: toHex(ethers.parseUnits(tx.value, tx.decimals ?? 18))
      }]
    });
  }
  async onDisconnect(callback) {
    this.provider.on("accountsChanged", (accounts) => {
      if (accounts.length === 0)
        callback();
    });
  }
  async onAccountsChanged(callback) {
    this.provider.on("accountsChanged", callback);
  }
  async onChainChanged(callback) {
    this.provider.on("chainChanged", (chain) => {
      return callback(Number.parseInt(chain, 16));
    });
  }
  async switchChain(chainId) {
    await this.provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: toHex(chainId) }]
    });
  }
  async getChainId() {
    return Number.parseInt(await this.provider.request({
      method: "eth_chainId",
    }), 16);
  }
  async estimateNativeGasFee(tx) {
    const { ethers } = await import("ethers");
    const account = await this.getAccount();
    const chainId = tx.chainId;
    const provider = getDefaultProvider(ethers, chainId);
    const gasLimit = await provider.estimateGas({
      to: tx.to,
      from: account,
      value: toHex(ethers.parseUnits(tx.value, tx.decimals ?? 18))
    });
    const feeData = await provider.getFeeData();
    return Number.parseFloat(ethers.formatEther((Number(feeData.maxFeePerGas ?? feeData.gasPrice)) * Number(gasLimit) * (chainId === 56 ? 3 : 1.5)));
  }
  async transferToContract(args) {
    const { ethers } = await import("ethers");
    const contract = new ethers.Contract(args.contractAddress, args.abi, this.provider);
    const { data } = args;
    const newData = contract.interface.encodeFunctionData("transfer", [data.to, ethers.parseUnits(cutoffDecimals(data.value, args.decimals), args.decimals).toString()]);
    const account = await this.getAccount();
    const tx = {
      to: args.contractAddress,
      from: account,
      value: ethers.parseEther("0.000").toString(),
      data: newData
    };
    await this.provider.request({
      method: "eth_sendTransaction",
      params: [tx]
    });
  }
  async signMessage(data) {
    const { ethers } = await import("ethers");
    const account = await this.getAccount();
    data = ethers.hexlify(ethers.toUtf8Bytes(data));
    const signedMessage = await this.provider.request({
      method: "personal_sign",
      params: [data, account]
    });
    return signedMessage;
  }
  async getAccount() {
    const accounts = await this.provider.request({
      method: "eth_requestAccounts",
      params: []
    });
    return accounts[0];
  }
}
export class WalletConnectCrossProvider extends CrossProvider {
  provider;
  modal;
  currentChainId = null;
  constructor(obj, manualCall = true) {
    super();
    if (manualCall)
      throw new Error("Can't call the constructor directly, use CrossProvider.from() instead");
    const provider = obj.provider;
    if (!provider)
      throw new Error("No provider");
    this.provider = provider;
    this.modal = obj.modal;
    this.currentChainId = obj.modal.getChainId() ?? null;
    this.modal.subscribeEvents((e) => {
      if (e.data.event === "SWITCH_NETWORK") {
        this.currentChainId = obj.modal.getChainId() ?? null;
      }
    });
  }
  static async from(obj) {
    return new WalletConnectCrossProvider(obj, false);
  }
  async disconnect() {
    this.modal.disconnect();
  }
  async sendNativeTransaction(tx) {
    const { ethers } = await import("ethers");
    const account = await this.getAccount();
    if (!account)
      throw new Error("No account selected");
    try {
      await this.provider.request({
          method: "eth_sendTransaction",
          params: [{
            to: tx.to,
            from: account,
            value: toHex(ethers.parseUnits(tx.value, tx.decimals ?? 18)),
            data: ""
          }]
      });
    }
    catch (e) {
      console.log(e);
      throw e;
    }
  }
  async onDisconnect(callback) {
    this.modal.subscribeProvider((state) => {
      if (!state.provider)
        callback();
    });
  }
  async onAccountsChanged(callback) {
  }
  async onChainChanged(callback) {
    this.modal.subscribeEvents((e) => {
      if (e.data.event === "SWITCH_NETWORK") {
        callback(this.modal.getChainId());
      }
    });
  }
  async switchChain(chainId) {
    await this.provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: toHex(chainId) }]
    });
  }
  async getChainId() {
      return this.currentChainId;
  }
  async estimateNativeGasFee(tx) {
    const { ethers } = await import("ethers");
    const account = await this.getAccount();
    const chainId = await this.getChainId();
    if (!chainId)
      return 0;
    const provider = getDefaultProvider(ethers, chainId);
    const gasLimit = await provider.estimateGas({
      to: tx.to,
      from: account,
      value: toHex(ethers.parseUnits(tx.value, tx.decimals ?? 18))
    });
    const feeData = await provider.getFeeData();
    return Number.parseFloat(ethers.formatEther((Number(feeData.maxFeePerGas ?? feeData.gasPrice)) * Number(gasLimit) * (chainId === 56 ? 3 : 1.5)));
  }
  async transferToContract(args) {
    const account = await this.getAccount();
    const { ethers } = await import("ethers");
    const contract = new ethers.Contract(args.contractAddress, args.abi);
    const { data } = args;
    const newData = contract.interface.encodeFunctionData("transfer", [data.to, ethers.parseUnits(cutoffDecimals(data.value, args.decimals), args.decimals).toString()]);
    const tx = {
      to: args.contractAddress,
      from: account,
      value: ethers.parseEther("0.000").toString(),
      data: newData,
    };
    try {
      await this.provider.request({
          method: "eth_sendTransaction",
          params: [tx]
      });
    }
    catch (e) {
      console.log(e);
      throw e;
    }
  }
  async signMessage(data) {
    const { ethers } = await import("ethers");
    const account = await this.getAccount();
    data = ethers.hexlify(ethers.toUtf8Bytes(data));
    const signedMessage = await this.provider.request({
      method: "personal_sign",
      params: [data, account]
    });
    return signedMessage;
  }
  async getAccount() {
      return this.modal.getAddress();
  }
}
