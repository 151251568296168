import erc20Abi from "./abi/erc20.json";
import bep20Abi from "./abi/bep20.json";

const eth = {
	chainId: 1,
	name: "Ethereum",
	currency: "ETH",
  label: "ERC-20",
	explorerUrl: "https://etherscan.io",
	rpcUrl: "https://cloudflare-eth.com",
  abi: erc20Abi
}

const bsc = {
	rpcUrl: "https://bsc-dataseed.binance.org/",
	chainId: 56,
	currency: "BNB",
  label: "BEP-20",
	explorerUrl: "https://bscscan.com",
	name: "Binance Smart Chain",
  abi: bep20Abi
}

export const chains = {
	eth,
	bsc
}