import { createContext, useContext, useEffect, useState } from "react";
import { Web3Context } from "../web3/Web3Context";
import * as api from "./api"
import toast from "react-hot-toast"

export const ApiContext = createContext({
  stage: null,
  user: null,
  token: null,
  paymentTokens: [],
  updateReferralCode: (newCode) => Promise.reject("No context")
})

export const ApiContextWrapper = (props) => {
  const [ stage, setStage ] = useState(null)
  const [ user, setUser ] = useState(null)
  const [ token, setToken ] = useState(null)
  const [ paymentTokens, setPaymentTokens ] = useState([])

  const web3 = useContext(Web3Context)

  useEffect(() => {
    if (!web3.account) {
      setUser(null)
      setToken(null)
      return
    }
    api.getUser(web3.account).then((res) => setUser(res.data))
      .catch((err) => toast.error("Error getting user"))
  }, [web3.account])

  useEffect(() => {
    api.getActiveStage().then((res) => setStage(res.data))
      .catch((err) => toast.error("Error getting stage"))
  }, [])

  useEffect(() => {
    const func = () => {
      api.getPrices()
        .then((res) => setPaymentTokens(res.data))
        .catch((err) => toast.error("Error getting payment tokens"))
    }
    func()
    const interval = setInterval(func, 60 * 1000)
    return () => clearInterval(interval)
  }, [])

	const getToken = async () => {
		if (!web3.connected || !web3.account) {
			throw new Error("Please connect your wallet")
		}
		const nonceRes = await api.getNonce(web3.account)
		toast("Confirm the message signature in your wallet")
		const signedMessage = await web3.signMessage(`Sign this message to activate your referral code! ID: ${nonceRes.data.nonce}`)
		const res = await api.postSignedMessage(web3.account, signedMessage)
		setToken(res.data.access)
		return res.data.access
	}

	const updateReferralCode = async (newCode) => {
    let currentToken = token;
		if (!web3.connected || !web3.account || !user) return
		if (!currentToken || new Date(token.expires).getTime() < Date.now()) currentToken = await getToken()
		await api.updateReferralCode(token.token, web3.account, newCode)
		const res = await api.getUser(web3.account)
		setUser(res.data)
	}

  const value = {
    stage,
    user,
    token,
    paymentTokens,
    updateReferralCode
  }

  return (
    <ApiContext.Provider value={value}>
      {props.children}
    </ApiContext.Provider>
  )
}