import axios from "axios"

export const apiFetch = async (
	url,
	data = {}
) => {
	const res = await axios({
		url,
		baseURL: process.env.REACT_APP_API_BASE_URL,
		...data
	})
	return res
}

const project = "bluenode"

export const getActiveStage = () => {
	return apiFetch(`/projects/${project}/stages/current`)
}

export const getPrices = () => {
	return apiFetch(`/projects/${project}/payment-tokens`)
}

export const getUser = (address) => {
	return apiFetch(`/projects/${project}/wallet/${address}`)
}

export const getNonce = (walletAddress) => {
	return apiFetch(`/projects/${project}/wallet/${walletAddress}/nonce`)
}

export const postSignedMessage = (walletAddress, signedMessage) => {
	return apiFetch(`/projects/${project}/wallet/${walletAddress}/signature`, {
		method: "POST",
		data: {signature: signedMessage}
	})
}

export const updateReferralCode = (token, address, referralCode) => {
	return apiFetch(`/projects/${project}/wallet/${address}`, {
		method: "PATCH",
		data: {referral_code: referralCode},
		headers: {
			"Authorization": `Bearer ${token}`
		}
	})
}